import React, { Component, useState } from 'react';
import { Redirect, Link } from "react-router-dom";
import HeaderMenu from '../components/HeaderMenu'
import './index.scss';
import { useSelector } from 'react-redux'
function Admin(props) {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, isAdmin } = useSelector((state) => state.auth);
  const Components = props.component;

  const [collapsed, setStatesss] = useState(window.innerWidth > 992 ? false : true)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  return (
    <div className="LiT-Body">
      <HeaderMenu collapsed={collapsed} toggleCollapsed={toggleCollapsed} />
      <div className={`ss_page-body ${collapsed && 'bodyCollapsed'}`}>
        {(userToken) ? <Components /> : <Redirect to="/login"></Redirect>}
      </div>
    </div>
  );
}

export default Admin;

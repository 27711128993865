import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { productsApi } from '../services/products';
import { actionApi } from '../services/action';
import { teamApi } from '../services/team';
import { loginApi } from '../services/login';
import { routeOneApi } from '../services/route_one';
import { googleMApi } from '../services/google_merchant';
import { storeApi } from '../services/store';
import { createProductApi } from '../services/create_products';
import authReducer from "../reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [routeOneApi.reducerPath]: routeOneApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [googleMApi.reducerPath]: googleMApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [actionApi.reducerPath]: actionApi.reducer,
    [createProductApi.reducerPath]: createProductApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware).concat(loginApi.middleware).concat(teamApi.middleware).concat(routeOneApi.middleware).concat(storeApi.middleware).concat(googleMApi.middleware).concat(actionApi.middleware).concat(createProductApi.middleware)
})
setupListeners(store.dispatch)

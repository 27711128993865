import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Table, Spinner, Accordion, Card, Dropdown, Badge, Modal, DropdownButton } from 'react-bootstrap'
// import { Line, Circle } from 'rc-progress';
import './index.scss';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Axios from "axios";
import { CKEditor } from 'ckeditor4-react';
import parse from "html-react-parser";
import { useSelector } from 'react-redux'

function ChangePassword() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const { register, handleSubmit, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm();

  const location = useLocation();
  const [isLoading, setLoading] = useState(false)
  const [isLoadingCreate, setLoadingCreate] = useState(false)
  const [isLoadingUpdate, setLoadingUpdate] = useState(false)
  const [isLoadingdoc, setLoadingdoc] = useState(false)
  const [show, setShow] = useState(false);
  const [getSuccessMsg, setSuccessMsg] = useState("");

 
  const [getMeesage, setMeesage] = useState("")
  const [getError, setError] = useState("")
  const [getPasswordChangeLoading, setPasswordChangeLoading] = useState(false)

  function changePassword(data) {
    setPasswordChangeLoading(true);

    let updateData = {
      "current_password": data.current_password,
      "new_password": data.new_password,
      "update_user_id": userId
    } 
    fetch(`${process.env.REACT_APP_API_CONNECT}/users`, {
      method: "POST",
      headers: {
        'X-User-Token': userToken,
        'X-User-Id': userId,
        'X-User-Role': userRole,
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(updateData)
    }).then((response) => {
      if (response.status === 201) {
        setMeesage('password changed successfully!')
        setError('success') 
        setPasswordChangeLoading(false)
      }
      else if (response.status === 422) {
        setMeesage('password does not match!')
        setError('error') 
        setPasswordChangeLoading(false)
      } else {
        setMeesage('Ann error occured')
        setError('error') 
        setPasswordChangeLoading(false)
      }

    })
  }






  return (
    <div className="Profile-page">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="change-password-card">
              {isLoading ? <div className="loadingBorder">&nbsp;</div> : <div style={{ "border": "1.5px solid #fff" }}></div>}
              <div className="storeCardB">
                <div className="updateButton">
                  <div>
                    <h5 className="listHeadng">Change Password</h5>
                  </div>
                </div>
                <div className="row padding20">

                  {getError === 'error' ? <div className="col-md-12" style={{ textAlign: "center" }}>
                    <div className="form-group">
                      <small className="alert alert-danger">{getMeesage}</small>
                    </div>
                  </div> : null}
                  {getError === 'success' ? <div className="col-md-12" style={{ textAlign: "center" }}>
                    <div className="form-group">
                      <small className="alert alert-success">{getMeesage}</small>
                    </div>
                  </div> : null} 
                  <div className="col-md-12">
                    <form onSubmit={handleSubmit2(changePassword)}>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>Current Password</label>
                            <input type="password" minLength="4" maxLength="16" className="form-control"
                              id="current_password" aria-invalid={errors2.current_password ? "true" : "false"} {...register2('current_password', { required: true })} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <label>New Password</label>
                            <input type="password" minLength="4" maxLength="16" className="form-control"
                              id="new_password" aria-invalid={errors2.new_password ? "true" : "false"} {...register2('new_password', { required: true })} />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            {getPasswordChangeLoading ? <Button variant="primary" size="sm" className="hghtbtn" disabled>Updating</Button> : <Button variant="primary" size="sm" className="hghtbtn" type="submit">Change Password</Button>}
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>

                </div>
 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;

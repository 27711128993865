import React, { Component } from 'react';
import { Redirect } from "react-router-dom";
import HeaderMenu from '../components/HeaderMenu';
import './index.scss';

function NotFound() {
  return (
    <div className="page-not-found">
      <div className="row newStore ">
        <div className="col-md-12">
          <div>
            <h4 style={{ fontSize: '1.5rem', fontWeight: 400 }}>Not Found</h4>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotFound;
